@use "sass:color";
@import '../../semantic-sass/scss/globals/mixins';

$blue-50: #ECF4FE;
$blue-20: #99C1FA;
$blue-250:#1A74F3;
$blue-200: #1377F7;
$blue-500: #0051C2;
$blue-550:#005BDA;
$blue-650: #0047A9;
$blue-default: #0065F2; 
$gray-20: theme('colors.lightest-grey');
$gray-50: #D7DBE0;
$gray-100: #D4D5D6;
$gray-200: #A9ABAD;
$gray-150: #696C70;
$gray-200: #494E53;
$gray-250: #3E4247;
$black-20: #6E7377;
$red: #F21400;
$orange: #FF5A00;
$yellow:#F28D00;
$white: #FFF;

$br-modal: 23px;
$font-main: "Centra No2", Helvetica, Arial, sans-serif!important;

@mixin is2-font($n, $w) {
    font-family: 'Centra No2';
    font-style: normal;
    font-weight: $w;
    src: url('./iscr2/fonts/CentraNo2-#{$n}.otf') format("opentype");
    font-display: swap;
}

@font-face {
    @include is2-font('Book', 400);
}

@font-face {
    @include is2-font('Medium', 500);
}

@font-face {
    @include is2-font('Bold', 700);
}

@mixin white_btn {
    background-color: $white;
    color: $gray-250;
    font-size: 1rem;
    &:hover {
        background-color: $blue-50;
    }
}
@mixin field_label_sm {
    background-color: #FFF;
    padding: 0 4px!important;
    width: fit-content;
    color: $gray-150;
    font-size: 11px;
    line-height: 16px;
    height: fit-content;
    display: inline-block;
    top: -8px;
    left: 16px;
    transition: border 0.2s, top 0.2s ease;
    z-index: 4;
    min-height: fit-content;
}

@mixin input_placeholder {
    content: "";
    background-color: $blue-50;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    border-radius: 8px;
}

@mixin input_size {
    padding: 12px 16px;
    min-height: 48px;
    font-size: 1rem;
    line-height: 24px;
}

body.iscr2 {
    --card-padding-x: 1.5rem;
    --card-padding-y: 2.25rem;

    #page-wrap > main {
        background-color: #F0F4F5;
        padding-bottom: 0;
    }

    // fonts
    font-family: $font-main;

    .ui.menu, .ui.button, .ui.modal > *:not(.icon), .ui.accordion >*, .ui.selection  {
        font-family: "Centra No2"!important;  
    }

    h1, h2, h3 {
        font-weight: 500;
    }
    h1 {
       @apply text-is2_2xl;
    }
    h2 {
        @apply text-is2_xl;
    }
    h3 {
        @apply text-is2_lg;
    }
    h4 {
        @apply text-is2_lg;
        font-weight: 400;
    }

    &.home .no-user {
        h1 {
            @apply text-is2_5xl;
            margin-bottom: 0;
            @include sm {
                font-size: 3rem;
                line-height: 3.25rem;
            }
        }
        h2 {
            @apply text-is2_4xl;
            @include sm {
                @apply text-is2_3xl;
            }
        }
        h3 {
            @apply text-is2_3xl;
        }
    }

    // Buttons
    .ui.button {
        padding: 12px 24px;
        border-radius: 8px;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 400;

        /*@media (max-width: 767px) {
            font-size: 0.9rem !important;
        }*/

		&.primary {
            background-color: $blue-default;
            color: $white;
            &:hover {
                background-color: $blue-200;
                color: $white;
            }
            &.white {
                @include white_btn;
            }
		}
        &.basic {
            outline: 1px solid $gray-100;
            box-shadow: none;
            @include white_btn;
            &:hover {
                border-color: transparent;
                color: $gray-250;
            }
            &.blue {
                outline: 2px solid $blue-default;
            }
		}
        &.white {
            @include white_btn; 
        }
        &.dark-blue{
            background-color: #005BDA;
            color: #FFF;
        }

        &.secondary {
            background: transparent;
            color: $blue-default;
            box-shadow: none;
            &:hover {
                background-color: $blue-50;
            }
            &:disabled {
                background: transparent!important;
                color: $gray-100!important;
            }
            &.white {
                color: $white;
                &:hover{
                    color: $blue-default;
                }
            }
            &.blue {
                color: $white;
                background-color: $blue-default;
                outline: 1px solid #fff;
                &:hover{
                    background-color: $blue-500;
                    color: $white;
                }
            }
        }

        &.rounded {
            border-radius: 52px;
            transition: all 0.3s ease-in-out;
            &:hover {
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.40);
            }
            &.bordered{
                border: 1px solid white;
            }
        }
       
        &.disabled {
            background-color: $gray-100!important;
            color: $gray-200!important;
            border: none!important;
            outline: none!important;
        }
        &.small {
            padding: 10px 24px;
        }
        &.large {
            padding: 12px 44px;
            font-size: 1rem;
            line-height: 20px;
            max-width: 100%;
        }
        @include sm {
            padding: 10px 24px!important;
        }
    }
    .ui.buttons, .ui.vertical.buttons {
        display: flex;
        .button {
            flex: auto;
            border-radius: 8px;
            margin-right: 12px;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    .ui.cards > .card, .ui.card {
        box-shadow: none;
    }
          
    // Modals
    .ui.modal:not(.fullscreen) {
        padding: 0!important;
        border-radius: $br-modal;
        min-height: 116px;

        @include lg {
            max-width: 810px;
        }
        &:not(:has(.scrolling)){ 
            @include hideScroll;
        }
        > .header{
            h2, >span {   
                font-size: 1.5;
                line-height: 2.25rem;
                @include sm {
                    font-size: 1.24rem!important;
                    line-height: 1.875;
                }
            }
            &:first-of-type {
                margin: 0;
                padding: 2rem;
                background-color: $blue-50;
                box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.02);
                border-top-left-radius: $br-modal;
                border-top-right-radius: $br-modal;
                @include sm {
                    padding: 1rem;
                }
            }
        }

        > .content{
            padding: 2rem;
            color: $gray-150;
            overflow-y: auto;
            h3 {
                font-size: 1.24rem!important;
                line-height: 1.875;
            }
            @include sm {
                padding: 1rem;
            }
            &.scrolling {
                max-height: 60vh;
                overflow-y: scroll;
            }
        }

        &:not(:has(.actions)) {
            padding-bottom: 2rem!important;
            border-bottom-left-radius: $br-modal;
            border-bottom-right-radius: $br-modal;
            .buttons {
                display: flex!important;
                flex-direction: row;
                gap: 8px;
                .button {
                    width: 50%;  
                }
                .primary {
                    background-color: $blue-default!important;
                }
            }
            > .content {
                padding-bottom: 1rem;
            }
        }
        &:has(.actions){
            > .content {
                box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.05) inset;
            }
        }

        .actions {
            background-color: white!important;
            padding: 1.5rem!important;
            border-bottom-left-radius: $br-modal;
            border-bottom-right-radius: $br-modal;
            display: flex;
            gap: 12px;
            background: none;
            border: none;
            > div {
                gap: 8px;
            }
            .button {
               width: 50%;
               .icon {
                    display: none;
               }
            }
            @include sm {
                padding: 1rem;
            }
        }

        > .close{
            border: none;
            top: 1rem;
            right: 1rem;
            opacity: 1;
            border: 1px solid $gray-150;
            border-radius: 8px;
            height: 20px;
            width: 20px;
            &:before {
                color: $gray-150;
                font-size: .4rem;
            }
        }
        .form {
            padding-top: 1rem;
            .ui.grid .row + .ui.divider {
                margin: 1rem 0;
            }
        }
    }
    .dialog-bottom-sheet > .panel .content {
        @include hideScroll;
    }

    .ui.divider {
        margin: 36px 0;
    }

    // Form
    // .ui.form {
        .field, .radio-group, .medicare-card .field:first-of-type {
            margin-bottom:0;
        }

        .field:not(.complex-field) {
            position: relative;
            &:has(.ui.input.fluid) {
                max-width: 100%;
            }

            .input > input, textarea {
                font-family: $font-main;
                &:empty, &:has([value=""]) {
                    position: relative;
                    border-radius: 8px;
                    background-color: transparent;
                    outline: transparent!important;
                    border: 1px solid transparent;

                    &::placeholder, &::-webkit-datetime-edit  {
                        color: transparent;
                        font-size: 14px;
                    }
                    &:focus, &:focus-visible {
                        background-color: transparent;
                        border: 1px solid $blue-default!important;
                        &::placeholder, &::-webkit-datetime-edit {
                           color: $gray-250;
                        }
                    }
                }
            }
            
            .ui.selection.dropdown,
            .input > input, textarea {
                position: relative;
                z-index: 3;
                &:focus, &:focus-visible {
                    border: 1px solid $blue-default!important;
                }
            }
            .ui.selection.dropdown {
                background:transparent;
                border: 1px solid transparent;
                border-radius: 8px;
                padding-left: 12px;
                &.active.dropdown,
                &.active.dropdown .menu {
                    border-color: $blue-default!important;
                    box-shadow: none;
                }
                &:has(>.text) {
                    border: 1px solid $gray-100;
                }
                .icon {
                    position: absolute;
                    top: 13px;
                }
            }
            .active.dropdown {
                z-index: 10!important;
            }

            .ui.input:has(> input[value=""]:not(.masked-input)),
            .search:has(> input[value=""]) {
                &:not(:focus-within)::before {
                    @include input_placeholder;
                }
            }

            textarea {
                box-shadow: 0 0 0px 1000px $blue-50 inset;
                -webkit-box-shadow: 0 0 0px 1000px $blue-50 inset;
            }

            .ui.input {
                width: 100%;
                > input {
                    border: none;
                    background-color: transparent;
                    transition: border-color 0.2s ease;
                    color: $gray-250!important;
                    @include input_size;
                    &:not([value=""]:not(.masked-input)) {
                        border: 1px solid $gray-100;
                        outline: transparent!important;
                        &::-webkit-datetime-edit {
                            color: $gray-250!important;
                        };
                    }
                }
            }

            &:not(:has(+label)) .input input {
                border: 1px solid  $gray-100;
                border-radius: 8px;
            }

            > label {
                // field label
                &:has(+.input > input, +.dropdown, + div > .search, + .search ){
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    line-height: 24px;
                    transition: 0.2s ease all;
                    color: $gray-250!important;
                    z-index: 2;
                    border-radius: 8px;
                    font-weight: normal!important;
                    @include input_size;
                    @include sm{
                        font-size: .9rem!important;
                    }
                }
                &:has(+ .dropdown) {
                    background-color: $blue-50!important;
                }
                &:has(+ .dropdown > .divider.text, +.dropdown:focus-visible) {
                    background-color: #FFF!important;
                }
                &:has(+.active.dropdown) {
                    z-index: 12!important;
                }
                &:has(
                    + .input > input:focus,
                    + .input > input:focus-visible,
                    + .input > input:not([value=""]),
                    + div > .search:not(:placeholder-shown),
                    + .dropdown > .divider.text,
                    + .input > .masked-input,
                    + .search input:focus,
                    + .search > .input > input:not([value=""])
                ){
                    @include field_label_sm;
                }
            }
            
            &.error {
                .input {
                    > input {
                        border: 1px solid $red;
                    }
                    &::before {
                        background-color: transparent!important;
                    }
                }
                .ui.dropdown .text {
                    background-color:transparent;
                }
                textarea {
                    border: 1px solid $red;
                }
            }

            &.disabled {
                .ui.input > input {
                    background-color: $gray-100;
                    color: $gray-200;
                }
                > label {
                    background-color: transparent;
                }
            }
        }

        .complex-input {
            @include input_size;
            border-radius: 8px;
        }

        label {
            display: block;
            margin-bottom: .5rem;
            color: $gray-250;
        }

        .section-label {
            font-size:1.2rem; 
            line-height: 1.875rem; 
            color: $gray-250;
            font-weight: 500;
        }

        .error {
            .helper {
                color: $red;
            }
            .field {
                background-color: #FFF;
                .ui.input > input {
                    border: 1px solid $red;
                    background-color: #FFF;
                }
                > label {
                    color: $red!important;
                    @include field_label_sm;
                }
            }
            textarea {
                border: 1px solid $red;
            }
        }
        .fc .fc-toolbar-title {
            font-size: 1.25rem;
            line-height: 1.875rem; 
        }
        .fc-times-view h3 {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    // }
 
    // checkbox
    .ui.checkbox:not(.toggle){
        label:before {
            height: 20px;
            width: 20px;
            border-radius: 4px;
            border: 1px solid $gray-200;
        }

        &:focus ~ label:before {
            border: 1px solid $gray-200;
        }
        input:disabled ~ label:before , input:hover ~ label:before {
            border-color: $gray-50;
        }
        input:checked {
            ~ label:before {
                background-color: #FFF;
            }
            ~ label:after {
                background: $blue-default;
                border-color: $blue-default;
                border-radius: 4px;
                height: 20px;
                width: 20px;
            }
        }

    }

    // radio
    .ui.checkbox input[type="radio"] {
        ~ label:before {
            border-radius: 50%;
            border-color: $gray-50;
            top: 0;
        }
        &:hover, &:disabled {
            ~ label:before {
                background-color: #F4F5F8;
                border-color: #000;
            }
        }
        &:checked ~ label:before {
            content: "";
            border: 1px solid #000;
        }
    }

    // dropdown
    .ui.selection{
        &.dropdown {
            @include input_size;

            .default.text {
                color: $gray-250;
            }
            .menu > .item:hover {
                background-color: $blue-50;
            }
        }
    }
    .ui.disabled.dropdown {
        background-color: $gray-100;
        .default.text {
            color: $gray-200;
        }
    }

    @media only screen and (max-width: 767px) {
        .ui.selection.dropdown .menu {
          max-height: 10rem;
        }
      }

    // Header
    .header {
        a:hover {
            cursor: pointer;
            color: #FFF;
        }
        .home  {
            @include xl {
                text-align: left;
            }
        }
       .ui.breadcrumb {
            a, i, .active, .section {
                color: $blue-20;
                font-size: 0.75rem;
                font-weight: 400;
            }
            a {
                text-decoration-color: transparent;
                @include hover_transition;
                &:hover {
                    text-decoration: underline;
                    text-decoration-color: $blue-20;
                }
            }
        };
    }
    // home header menu
    .tooltip {
        top: 90%;
        &::before {
            content: "\25E5";
            position: absolute;
            left: -6px;
            top: 2px;
            font-size: 14px;
            line-height: 10px;
            color: $blue-500;
        }
    }
    .home-menu {
        position: relative;
        z-index: 19;
      
        .menu-item:nth-last-child(-n+3) {
            .tooltip {
                right: 0;
                left: -264px;
                margin-left: 0;
                border-top-right-radius: 0;
                border-top-left-radius: 24px;
                &::before {
                    left: auto;
                    right: -6px;
                    transform: rotate(270deg);
                }
            }
        }
    }

    overflow-y: unset;
    &.side-open {
        overflow-y: hidden!important;
        @include lg {
            padding-right: 15px!important; //scroll
            background-color: $blue-default;
        }
    }
    &.home.menu-open {
        @include sm {
            background-color: $blue-default;
        }
    }

    // Side menus
    .bm-menu-wrap-iscr2 {
        padding-bottom: 62px;
        max-width:100vw;
        width: 100% !important;
        z-index:100010 !important;
        @include hideScroll;
        
        .bm-menu {
            background-color: white !important;
            @include hideScroll;
            .side-logo {
                width: fit-content;
            }
            @include sm {
                margin-top: 62px;
            }
            .ui.vertical.inverted.menu:first-of-type {
                margin-top: 0;
            }
        }
        @include lg {
            padding-bottom: 80px;
            width: 741px!important;
        }
       
    }
    .side-btn {
        font-size: 1rem;
        line-height: 21px;
    }

    @include lg {
        .bm-menu {
            margin-top: 0;
        }
        .bm-overlay,  .bm-menu-wrap {
            margin-top: 80px;
        }
    }


    // Home logged in
    .home-main {
        position: relative;
        @include lg{
            &::before {
                content:"";
                background-color:#FFF;
                position: absolute;
                top: -119.5px;
                right: 0;
                height: 120px;
                width: 120px;
                clip-path: path("M0 0Q0 120 0 120Q120 120 120 0Q1 0 0 0H10000V100000H0Z");
            }
        }
    }
    &.home  .item.hist-item {
        box-shadow: none!important;
    }
   
    &.profile {
        #user_signed_area {
            max-width: 744px!important;
            margin: 44px auto;
            label {
                font-weight: normal;
                font-size: 1rem;
            }
            .checkbox >label {
                font-weight: normal;
                font-size: 1rem;
                color: $gray-250;
            }
            .ui.grid > .row:not(:first-child) {
                margin-top: 0;
                padding: .5rem 0;
            }
            .column:not(:has(> .field)) {
                padding: 0!important;
            }
            .ui.segment, .mfa {
                background-color: #FFF!important;
                margin-top: 0;
                margin-bottom: 16px!important;
                padding: 36px 24px;
                box-shadow: none;
                border: none;
                @include sm {
                    border-radius: 0;
                    margin-top: 0;
                    margin-bottom: 4px!important;
                }
            }
            .mfa > .ui.segment {
                padding: 0;
                margin: 0;
            }
            @include sm {
                margin-top: 0;
            }
        }
        .dva {
            min-height: 120px!important;
        }
    }


    .onboarding, .mfa {
        width: 100%!important;
        .segment {
            border-radius: 8px;
            padding: 36px 24px;
            box-shadow: none!important;
        }
        > div {
            border: none!important;
        }
        .ui.list > .item .header, h5 {
            @apply text-is2_xl;
            font-family: $font-main;
            margin-bottom: 12px;
            font-weight: 500;
        }
        .item {
            padding: 0;
        }
    }

    .last-order {
        .ui.button{
            @include white_btn;
            width: calc(50% - 1rem);
            outline: 1px solid $gray-100;
            box-shadow: none;
            &:hover {
                border-color: transparent;
            } 
            @include sm {
                width: 100%;
            }
        }
    }
    &.history  .ui.items.history-list > .item {
        padding: 1em 0em !important;
    }
    .ui.items.history-list > .item {
        padding: 0!important;
        a.ui.button, 
        button.ui.button {
            background-color: $white!important;
            color: $gray-250!important;
            &:hover {
                background-color: $blue-50 !important;
            }
            outline: 1px solid $gray-100;
            box-shadow: none;
            font-size: 1rem;
            &:hover {
                border-color: transparent;
            }
            @media (max-width: 767px) {
                font-size: 0.95rem !important;
            }
        }
    }

    // Accordion
    .faq .ui.styled.accordion {
        border: 1px solid $gray-20;
        .title, .accord-left .ui.styled.accordion .title {
            color: $black-20;
            border-bottom: 1px solid $gray-20;
            padding: 20px 16px;
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 500;
            
            .icon {
                transform: none;
                &:before {
                    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2213%22%20height%3D%2210%22%20viewBox%3D%220%200%2013%2010%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M7.3%209.05443C6.9%209.58776%206.1%209.58776%205.7%209.05443L0.2%201.72109C-0.294427%201.06186%200.175955%200.121093%201%200.121094L12%200.121095C12.824%200.121095%2013.2944%201.06186%2012.8%201.72109L7.3%209.05443Z%22%20fill%3D%22%230065F2%22%2F%3E%3C%2Fsvg%3E");
                }
            }
            &.active {
                .icon {
                    transform: rotate(180deg);
                    @include hover_transition;
                }
            }
        }
        .content {
            opacity: 0;
            &.active {
                opacity: 1;
                animation: fadein .5s ease-in;
            }
        }
    }

    // For styling the added-to-cart toast notification
    // Bit of a dirty hack, but it works
    #actionable .Toastify__toast-container--bottom-center:has(#added-to-cart) {
        width: max-content;
        max-width: 100vw;
        padding: 0px 15px;
    }

    // Menu
    &.home #main-menu {
        &.no-user {
            @include xl {
                position: sticky;
            }
        }
    }
    @include sm {
        #popup-login-cont {
            top:50px!important;
            margin-top: 0!important;
        }
    }
    &.home, &.login, &.signup {
        #main-menu .primary.button.sign-in {
            background-color: $yellow!important;
        }
    }

    #main-menu {
        padding: 0;
        position: sticky;
        width: 100%;
        z-index: 20;
        background-color: $blue-default!important;
        color: #FFF;

        .item.burger {
            color: #FFF;
            padding: 4px 12px;
            border-radius: 41px;
            &:hover {
                color: #FFF;
            }
            &.active {
                background-color: $blue-500;
            }
            @include lg {
                margin-left: 24px;
                padding: 0 20px!important;
                height: 44px;
            }
        }
        .logo {
            height: 28px;
            width: auto;
            filter: brightness(0) invert(1);
        }
        .user-menu > .item.dropdown  {
            color: #FFF;
            padding-top: 0;
            padding-bottom: 0;
            z-index: 50;
            border-radius: 33px;
            text-decoration: underline;
            text-underline-position: under;
            text-decoration-color: transparent;
            &:focus {
                outline: none;
            }
            &:hover {
                color: #fff;
                text-decoration-color: #fff;
                @include hover_transition;
            }
            @include lg {
                height: 36px;
                padding: 6px 14px;
            }
            &.active {
                background-color: $blue-550;
                text-decoration: none;
            }
        }

        @include lg {
            box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.10);
        }

        .item, .ui.primary.button {
            font-size: 1rem;
            line-height: 20px;
            font-weight: 400;
        }

        .top-menu {
            position: relative;
            height: 56px;
            margin-top: 0;
            @include lg {
                height: 80px;
            }
        } 

        &.user-in {
            .user-menu {
                padding-bottom: 0;
                .item {
                    @include sm {
                        padding:4px;
                    }
                }
            }
            .ui.container {
                max-width: 100%!important;
                > .menu {
                    align-items: center;
                };
            }
            // admin menu
            .simple.dropdown.item, .item.main {
                color: #FFF;
            }
            // admin header msg
            .ui.message {
                min-height: 50px;
                margin-bottom: .5rem;
                .header {
                    padding: 0;
                    font-size: 1rem;
                }
                > .icon:not(.close){
                    font-size: 1.5rem;
                    &::before  {
                        font-size: 1.5rem;
                    }
                }
            }
        }

        &.no-user {
            box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.10);

            .item.ui.primary.button.sign-in {
                background-color: $blue-250;
                border-radius: 41px;
                padding: 4px 12px;
                color: #FFF;
                line-height: 20px;
                @include lg {
                    padding: 12px 20px;
                }
                &:hover {
                    background-color: $blue-500;
                }
            }
        }

        @include lg {
            .container:first-of-type {
                width: 100%;
                max-width: 100%!important;
                padding-left: 1rem;
            }
        }

        @include sm {
            box-shadow: none;
            border-bottom: none;
            .logo {
                height: 24px;
            }
        }
    }

    // user menu dialog
    .user-menu-container {
        color: $gray-250;
        .header {
            .button.icon {
                padding: 10px!important;
                background-color: transparent;
                border-radius: 50%;
                height: 42px;
                width: 42px;
                &:focus, &:focus-visible {
                    box-shadow: none!important;
                }
            }
            i.icon {
                border: none;
            }
        }

        .menu-item {
            border-top: 1px solid $blue-50;
            padding: 8px 0;
            display: flex;
            justify-items: center;
            align-items: center;
            gap: 12px;
            cursor: pointer;

            .icon {
                width:32px;
                height:32px;
                color: $gray-150;
                margin-right: 0;
                &::before {
                    font-size: 1.5rem;
                    line-height: 32px;
                    font-weight: normal;
                }
            }
            &:hover {
                color: $blue-default!important;
                background-color: transparent!important;
            }
            > a, > .icon {
                &:focus, &:focus-visible {
                    color: $blue-default!important;
                    border: none!important;
                    outline: none!important;
                }
            }
        }
        @include lg {
            margin-top: 22px;
        }
    }

    --notifications-theme-main: 214.96 100% 47.45%;
    --notifications-indicator-icon: 0 0% 100%;
    --notifications-indicator-icon-active: 214.95 100% 42.75%;
    --notifications-list-header: var(--notifications-theme-main);
}
